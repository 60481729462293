import { acceptHMRUpdate, defineStore } from 'pinia';
import type {
  CartStoreState,
  CartType,
  ModifyCartItem,
  UpdateCartItem,
  CartActionModel,
  AddProductsOptions
} from './model';
import { modifyCartItemSchema } from './model';
import { useCartLink } from './useCartLink';
import { extractError } from '~/utils/extractError';
import type {
  AddProductsToCartMutationVariables,
  UpdateProductsInCartMutationVariables,
  CartItemUpdateInput,
  CartUserErrorsFragment
} from '~/lib/Shop/generated/schema';
import { useCartCookie } from '~/composables/useCartCookie';
import type { CartItemPutResponseModel } from '~/server/api/magento/cart/item.put';
import type { UpdateCartResponseModel } from '~/server/api/magento/cart/index.put';
import type { DeleteCartItemResponseModel } from '~/server/api/magento/cart/item.delete';
import type { GetCartResponseModel } from '~/server/api/magento/cart/index.post';
import { usePdpConfiguration } from '~/composables/usePdpConfiguration';
import { useTriggerAddToCartEvent } from '~/stores/cartStore/useTriggerAddToCartEvent';

function extractUserErrors(
  userErrors: ReadonlyArray<CartUserErrorsFragment> | undefined | null
) {
  if (!userErrors) {
    return;
  }

  const errors = userErrors.map(
    (error: CartUserErrorsFragment) => error.message
  );

  return errors.length === 0 ? undefined : errors;
}

function retrieveProductSku(cartItem: ModifyCartItem): string {
  if ('sku' in cartItem) {
    return cartItem.sku;
  }

  return cartItem.decoratedProduct!.sku!;
}

/**
 * Provides a store for the shopping cart.
 * GUI Related operations should be moved to a separate store / component /
 * composable and depend on this store.
 */
export const useCartStore = defineStore('shop-cart', {
  state: (): CartStoreState => ({
    store: 'emea_de',
    locale: 'de-de',
    lastAction: null,
    loading: false,
    carts: {
      cart: null,
      requestCart: null
    },
    activeCartType: 'cart'
  }),
  getters: {
    /**
     * This is NOT in sync with the active cart from the widget store.
     */
    activeCart: (state) => {
      return state.carts[state.activeCartType];
    },
    activeCartItemCount(): number | undefined {
      // return `undefined` instead of 0
      return this.activeCart?.items?.length || undefined;
    },
    currentCartLink: (state): string | undefined =>
      useCartLink(state.activeCartType),
    availableCarts(): Record<string, string | undefined | null> {
      return Object.fromEntries(
        Object.entries(this.cartIds).map(([type, storeCartIdMap]) => [
          type,
          storeCartIdMap?.[this.store]
        ])
      );
    },
    cartIds: (state) => ({
      // could use ref directly - but looses actual function to sync cookie
      cart: useCartCookie('cart', state.locale).value,
      requestCart: useCartCookie('requestCart', state.locale).value
    }),
    shopBaseUrlMap(): Record<string, string | undefined> {
      const pdpConfiguration = usePdpConfiguration();

      return {
        default: pdpConfiguration.value?.defaultConfiguration?.baseUrl,
        ...Object.fromEntries(
          Object.entries(
            pdpConfiguration.value?.configurationByLocale ?? {}
          ).map(([key, value]) => [key, value.baseUrl])
        )
      };
    },
    profileLink(): string {
      return `${
        this.shopBaseUrlMap[this.locale] ?? this.shopBaseUrlMap.default
      }/${this.store}/customer/account/login/`;
    }
  },
  actions: {
    /**
     * Adding a product which is already in the cart will increase the
     * amount accordingly.
     * Performs internal error handling.
     *
     * @param cartItems The items to add.
     * @param cartType The cart type to use.
     * @param options Additional options to use.
     */
    async addProducts(
      cartItems: ReadonlyArray<ModifyCartItem>,
      cartType: CartType,
      options?: AddProductsOptions
    ) {
      const logger = useLogger();
      if (!cartItems.length) {
        logger.warn(
          'cartStore: no elements defined to add to cart - aborting.'
        );

        return;
      }

      const parsedItems = cartItems.map((item) =>
        modifyCartItemSchema.parse(item)
      );

      this.loading = true;

      const action: CartActionModel = {
        type: 'addItems'
      };

      try {
        // the last modified cart should be active
        // @see https://gcp.baslerweb.com/jira/browse/WEB2-1577
        this.activeCartType = cartType;
        const addItemResponse = await $fetch<CartItemPutResponseModel>(
          '/api/magento/cart/item',
          {
            method: 'PUT',
            query: {
              store: this.store,
              locale: this.locale,
              type: cartType
            },
            body: {
              cartItems: parsedItems.map((cartItem) => ({
                sku: retrieveProductSku(cartItem),
                quantity: cartItem.quantity,
                config_id: cartItem?.config_id ?? ''
              }))
            } satisfies Pick<AddProductsToCartMutationVariables, 'cartItems'>
          }
        );
        if (addItemResponse) {
          this.carts[cartType] = addItemResponse.cart;
          // XXX: proper typing
          action.error = extractUserErrors(
            addItemResponse.user_errors as never
          );
        }

        useTriggerAddToCartEvent(
          parsedItems,
          cartType,
          this.carts[cartType]!.id,
          this.store,
          this.locale,
          options
        );
      } catch (e) {
        action.error = extractError(e);
      } finally {
        this.loading = false;
        this.lastAction = action;
      }
    },
    /**
     * Updates the given cart with the given items.
     * If no cart type is given, the current active cart is used.
     * Performs internal error handling.
     *
     * @param cartItems The update items.
     * @param cartType The cart type to use.
     */
    async updateProducts(
      cartItems: ReadonlyArray<UpdateCartItem>,
      cartType: CartType
    ) {
      const logger = useLogger();
      if (!cartItems.length) {
        logger.warn(
          'cartStore: no elements defined to update in cart - aborting.'
        );

        return;
      }

      this.loading = true;

      const action: CartActionModel = {
        type: 'updateItems'
      };

      try {
        // the last modified cart should be active
        // @see https://gcp.baslerweb.com/jira/browse/WEB2-1577
        this.activeCartType = cartType;
        this.carts[cartType] = await $fetch<UpdateCartResponseModel>(
          '/api/magento/cart',
          {
            method: 'PUT',
            query: {
              store: this.store,
              locale: this.locale,
              type: cartType
            },
            body: {
              cartItems: cartItems.map(
                (cartItem) =>
                  ({
                    cart_item_uid: cartItem.uid,
                    quantity: cartItem.quantity
                  } satisfies CartItemUpdateInput)
              )
            } satisfies Pick<UpdateProductsInCartMutationVariables, 'cartItems'>
          }
        );
      } catch (e) {
        action.error = extractError(e);
      } finally {
        this.lastAction = action;
        this.loading = false;
      }
    },
    /**
     * Removes the given product from the given cart type.
     * If no cart type is given, the current active cart is used.
     * Performs internal error handling.
     *
     * @param productUid The product uid to remove.
     * @param cartType The cart type to use.
     */
    async removeFromCart(productUid: string, cartType?: CartType) {
      const usedCartType = cartType ?? this.activeCartType;
      this.loading = true;
      const action: CartActionModel = {
        type: 'removeItem'
      };
      try {
        // the last modified cart should be active
        // @see https://gcp.baslerweb.com/jira/browse/WEB2-1577
        this.activeCartType = usedCartType;
        this.carts[usedCartType] = await $fetch<DeleteCartItemResponseModel>(
          '/api/magento/cart/item',
          {
            method: 'DELETE',
            query: {
              store: this.store,
              locale: this.locale,
              type: usedCartType,
              cartItemUid: productUid
            }
          }
        );
      } catch (e) {
        action.error = extractError(e);
      } finally {
        this.lastAction = action;
        this.loading = false;
      }
    },
    /**
     * (Re)loads the given cart type.
     * This ensures, that a cart actually exists (if not, a new one
     * is created).
     * If no cart type is given, the current active cart is used.
     * Performs internal error handling.
     *
     * @param type The cart type to load.
     */
    async loadCart(type?: CartType) {
      const usedCartType = type ?? this.activeCartType;

      this.loading = true;
      const action: CartActionModel = {
        type: 'loadCart'
      };

      try {
        this.carts[usedCartType] = await $fetch<GetCartResponseModel>(
          '/api/magento/cart',
          {
            method: 'POST',
            query: {
              type: usedCartType,
              locale: this.locale,
              store: this.store
            }
          }
        );
      } catch (e) {
        action.error = extractError(e);
      } finally {
        this.lastAction = action;
        this.loading = false;
      }
    },
    /**
     * Loads all available carts ({@link this.availableCarts}).
     * Performs a request per available {@link CartType}.
     */
    async init() {
      await Promise.all(
        Object.entries(this.availableCarts).map(async ([type, cartId]) => {
          if (cartId) {
            await this.loadCart(type as CartType);
          }
        })
      );
      // display the cart which has actually items.
      // if no cart has "isActive" set - the empty dialog is displayed.
      const firstCartTypeWithItems = Object.entries(this.carts).find(
        ([_, cart]) => cart && cart.items && cart.items.length > 0
      )?.[0] as CartType | undefined;
      if (firstCartTypeWithItems) {
        this.activeCartType = firstCartTypeWithItems;
      }
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
