import { convertContentfulToWebappLocaleCode } from './convertContentfulToWebappLocaleCode';
import { useHostname } from '~/composables/useHostname';

/**
 * Creates an urls string with the given options.
 *
 * @param locale The locale to use.
 * @param slug The slug to use.
 * @param query The query without "?"
 * @param anchor The anchor without "#".
 * @param withAbsolutePath If true, the protocol+host will be prepended. (e.g. https://...)
 *
 * XXX: refactor to use object with deconstruction.
 */
export function buildUrlString(
  locale: string,
  slug = '',
  query?: string,
  anchor?: string,
  withAbsolutePath: string | boolean = false
): string {
  let serverUrlOriginSegment = '';
  // Workaround for "useNuxtApp" can only be access in composable
  // Maybe remove access to useRuntimeConfig here completely?
  if (typeof withAbsolutePath === 'string') {
    serverUrlOriginSegment = withAbsolutePath;
  } else if (withAbsolutePath) {
    serverUrlOriginSegment = useHostname(locale);
  }

  if (locale.includes('/')) {
    locale = locale.split('/').filter(Boolean)[0];
  }

  const path = [convertContentfulToWebappLocaleCode(locale), ...slug.split('/')]
    .filter(Boolean)
    .join('/');

  const anchorSegment = anchor ? '#' + anchor : '';
  const querySegment = query ? '?' + query : '';
  return `${serverUrlOriginSegment}/${path}/${querySegment}${anchorSegment}`;
}
