const localeMapping: Record<
  Lowercase<string>,
  Lowercase<string> | undefined
> = {
  'zh-hant-tw': 'zh-tw'
};

export function convertContentfulToWebappLocaleCode(
  locale: string
): Lowercase<string> {
  const lowercasedLocale = locale.toLowerCase() as Lowercase<string>;

  return localeMapping[lowercasedLocale] ?? lowercasedLocale;
}
