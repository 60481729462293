import type { ComputedRef } from 'vue';
import type { DecoratedProduct } from '~/utils/shop/DecoratedProduct';

export default defineNuxtPlugin(() => {
  const { $locale } = useNuxtApp();

  function createProductDetailLink(product: DecoratedProduct) {
    const locale = useLocale();

    return buildPdpUrl(locale.value, product.urlKey ?? '');
  }

  const configuration = usePdpConfiguration();

  const shopStore: ComputedRef<string> = computed<string>(
    () =>
      configuration.value?.localeStoreMap?.[$locale.value] ??
      configuration.value?.localeStoreMap?.default ??
      'emea_en'
  );

  return {
    provide: {
      createProductDetailLink,
      shopStore
    }
  };
});
