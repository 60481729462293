export type OldLocales = 'en' | 'de' | 'ru' | 'cn' | 'ko' | 'jp' | 'zh-tw';

export const oldToCurrentLocaleMap: Record<
  OldLocales,
  Lowercase<string> | undefined
> = {
  en: 'en',
  de: 'de-de',
  ru: 'ru-ru',
  cn: 'zh-cn',
  ko: 'ko-kr',
  jp: 'ja-jp',
  'zh-tw': 'zh-tw'
};
