export function extractError(e: unknown): string | undefined {
  if (e instanceof Error) {
    if (e.name && e.name !== 'Error') {
      return `${e.name}: ${e.message}`;
    }

    return e.message;
  }
  if (typeof e === 'string') {
    return e;
  }
}
