type BreakpointState = {
  specialHeaderBreakpoint: boolean;
};

export default defineNuxtPlugin(() => {
  /**
   * Determines if the current device is a mobile device.
   * The variable is also defined in "@hypercodestudio/basler-components" scss.
   * This function relies on {@link window.matchMedia} and always returns
   * `false` if this function is not available.
   */
  function isSpecialHeaderBreakpoint(): boolean {
    return (
      typeof window?.matchMedia !== 'undefined' &&
      !window.matchMedia('(min-width: 1024px)').matches
    );
  }

  const breakpoints = reactive<BreakpointState>({
    specialHeaderBreakpoint: isSpecialHeaderBreakpoint()
  });

  const resizeListener = debounce(() => {
    breakpoints.specialHeaderBreakpoint = isSpecialHeaderBreakpoint();
  }, 200);

  window.addEventListener('resize', resizeListener);

  return {
    provide: {
      breakpoints
    }
  };
});
