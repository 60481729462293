import { isDefined } from './guards/isDefined';
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import type { RedirectCacheType } from '~/lib/ContentfulService/ContentfulPageRedirectService';
import { convertContentfulToWebappLocaleCode } from '~/utils/convertContentfulToWebappLocaleCode';
import { buildUrlString } from '~/utils/buildUrlString';

export async function createNewUrlFromRedirectAndCurrentUrl(
  redirect: RedirectCacheType,
  currentUrl: URL,
  getGeoRedirectLocale?: () => Promise<LOCALE_CODE | undefined>
): Promise<URL | undefined> {
  let destinationUrl = redirect.destinationUrl;

  if (!isDefined(destinationUrl)) {
    const geoRedirectLocale = await getGeoRedirectLocale?.();

    const webappLocaleCode = isDefined(geoRedirectLocale)
      ? convertContentfulToWebappLocaleCode(geoRedirectLocale)
      : 'en';

    const webappLocaleSlug =
      redirect.geoRedirectLocaleToDestinationSlugMap?.[webappLocaleCode];

    if (!webappLocaleCode || !webappLocaleSlug) {
      return;
    }

    destinationUrl = buildUrlString(webappLocaleCode, webappLocaleSlug);
  }

  /**
   * Default url origin is the destinationUrl, fallback is the current origin.
   *
   * Reference https://developer.mozilla.org/en-US/docs/Web/API/URL/URL#parameters
   * > If url is a relative URL, base is required, and will be used as the base URL.
   * If url is an absolute URL, a given base will be ignored.
   */
  const newUrl = new URL(destinationUrl, currentUrl.origin);

  if (redirect.queryStringForwarding) {
    for (const [key, value] of currentUrl.searchParams.entries()) {
      newUrl.searchParams.append(key, value);
    }
  }

  if (redirect.pathForwarding) {
    newUrl.pathname = currentUrl.pathname;
  }

  return newUrl;
}
