import type { ProductFiltersModel } from '~/stores/productFilterStore/model';

export function createForwardedValues(
  formValues: ProductFiltersModel
): ProductFiltersModel {
  return Object.fromEntries(
    Object.entries(formValues)
      // multiple selected filters should not be forwarded
      // @see https://gcp.baslerweb.com/jira/browse/WEB2-1852
      .filter(([_, values]) => values.length === 1)
  );
}
