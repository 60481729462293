/**
 * Strips Query and Search Parameters from the given url.
 *
 * @param url The url to use.
 */
export function getPlainUrl(url: URL | string) {
  // clone the url to avoid manipulation
  const cloned = new URL(url);
  cloned.hash = '';

  if (cloned.searchParams) {
    Array.from(cloned.searchParams.keys()).forEach((key) => {
      cloned.searchParams.delete(key);
    });
  }

  return cloned.toString();
}
