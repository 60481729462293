import { z } from 'zod';
import { parseContentfulLocale } from '~/utils/validation/parseContentfulLocale';

export const geolocationCookieSchema = z.object({
  detectedCountry: z.string().optional(),
  detectedLocale: parseContentfulLocale.optional(),
  /**
   * @example "en-US"
   */
  preferredLocale: z.string().nullable().optional(),
  /**
   * @example "EE"
   */
  preferredCountry: z.string().nullable().optional()
});

export type GeoLocationCookieSchemaModel = z.infer<
  typeof geolocationCookieSchema
>;
