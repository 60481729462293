import type { NuxtApp } from '#app';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    $nuxt: NuxtApp;
  }
}

export default defineNuxtPlugin(() => {
  const { $pinia } = useNuxtApp();

  // @see https://github.com/vuejs/pinia/discussions/835
  $pinia.use(() => ({ $nuxt: markRaw(useNuxtApp()) }));

  return {};
});
