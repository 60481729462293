import type { RouterConfig } from '@nuxt/schema';
import { scrollBehavior } from './defaultScrollBehavior';

export default {
  scrollBehavior: (to, from, savedPosition) => {
    const { $globalPageSettings } = useNuxtApp();
    const documentCenterStartSlug =
      $globalPageSettings.value?.documentCenterPage?.metadata?.slug;
    const softwareCenterStartSlug =
      $globalPageSettings.value?.softwareCenterPage?.metadata?.slug;

    // While navigating to document center detail pages (modal), we keep the
    // current scroll position.
    // @see https://gcp.baslerweb.com/jira/browse/DBP-965
    if (
      documentCenterStartSlug &&
      to.path.includes(documentCenterStartSlug) &&
      // direct links to the document center page should keep scroll behavior
      (!to.path.endsWith(documentCenterStartSlug + '/') ||
        // links back from a detail modal should not change scroll position
        from.path.includes(documentCenterStartSlug))
    ) {
      return;
    }

    // While navigating to software center detail pages (modal), we keep the
    // current scroll position.
    if (
      softwareCenterStartSlug &&
      to.path.includes(softwareCenterStartSlug) &&
      // direct links to the software center page should keep scroll behavior
      (!to.path.endsWith(softwareCenterStartSlug + '/') ||
        // links back from a detail modal should not change scroll position
        from.path.includes(softwareCenterStartSlug))
    ) {
      return;
    }

    // While navigating from a download modal, we keep the
    // current scroll position
    // @see https://gcp.baslerweb.com/jira/browse/DBP-990
    if (
      to.hash?.includes('download=') ||
      from?.hash?.includes('download=') ||
      to.hash?.includes('software=') ||
      from?.hash?.includes('software=')
    ) {
      return;
    }

    // use default behavior
    return scrollBehavior(to, from, savedPosition);
  }
} satisfies RouterConfig;
