import type { DefaultConfigOptions } from '@formkit/vue';
import { createFloatingLabelsPlugin } from '@formkit/addons';
import { arrowUp, arrowDown } from '@formkit/icons';
import IconMagnifier from '~/assets/magnifier.svg?raw';

const config: DefaultConfigOptions = {
  icons: {
    // XXX: currently assets are not exposed by hypercode bundle and copied manually
    search: IconMagnifier,
    arrowUp,
    arrowDown
  },
  plugins: [
    createFloatingLabelsPlugin({
      useAsDefault: true // defaults to false
    })
  ]
};

export default config;
