import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45enforce_45lowercased_45locale_45global from "/app/middleware/01.enforceLowercasedLocale.global.ts";
import _02_45enforce_45tailing_45slash_45middleware_45global from "/app/middleware/02.enforceTailingSlashMiddleware.global.ts";
import _03_45page_45redirect_45global from "/app/middleware/03.pageRedirect.global.ts";
import _04_45old_45pdp_45redirect_45global from "/app/middleware/04.oldPDPRedirect.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45enforce_45lowercased_45locale_45global,
  _02_45enforce_45tailing_45slash_45middleware_45global,
  _03_45page_45redirect_45global,
  _04_45old_45pdp_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}