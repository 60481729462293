/**
 * This is a custom error.
 *
 * @see https://gcp.baslerweb.com/jira/browse/WEB2-2411
 */
export type ContentfulEntryNotAvailableInLanguageError = {
  sys: {
    id: 'notAvailableInLanguage';
    type: 'error';
  };
  details: {
    type: 'Link';
    linkType: string;
    id: string;
  };
};

export function isEntryNotAvailableInLanguageError(
  value: unknown
): value is ContentfulEntryNotAvailableInLanguageError {
  return Boolean(
    value &&
      typeof value === 'object' &&
      'sys' in value &&
      value.sys &&
      typeof value.sys === 'object' &&
      'id' in value.sys &&
      value.sys.id === 'notAvailableInLanguage' &&
      'type' in value.sys &&
      value.sys.type === 'error' &&
      'details' in value &&
      value.details &&
      typeof value.details === 'object' &&
      'type' in value.details &&
      value.details.type === 'Link' &&
      'id' in value.details &&
      typeof value.details.id === 'string' &&
      'linkType' in value.details &&
      typeof value.details.linkType === 'string'
  );
}
