import type { Ref } from 'vue';
import type { SpecificationMapping } from '~/utils/shop/createSpecifications';
import { type ProductAttributeFilterInput } from '~/lib/Shop/generated/schema';

/**
 * TODO: move to explicit pdpConfiguration package with all function from
 *  '~/utils/shop/createSpecifications
 */
export type PdpConfiguration = {
  defaultConfiguration: StoreConfiguration;
  configurationByLocale: Record<string, StoreConfiguration>;
  localeCartCookieDomainMap: Record<string, string> & { default: string };
  localeStoreMap: Record<string, string>;
  variables: Record<string, string>;
  attributeSets: Record<string, number>;
  relatedProductMapping: Record<
    string,
    Array<{
      dictionary?: string;
      sets: string[];
      filter?: ProductAttributeFilterInput;
    }>
  >;
  specificationMappings: SpecificationMapping[];
};

type StoreConfiguration = {
  shoppingCartUrl: string;
  requestCartUrl: string;
  baseUrl: string;
};

/**
 * This composable relies on {@link useNuxtApp}.
 */
export function usePdpConfiguration(): Readonly<
  Ref<PdpConfiguration | undefined>
> {
  const { $globalPageSettings } = useNuxtApp();

  return readonly(
    computed(() => {
      if (!$globalPageSettings.value) {
        return;
      }

      return {
        attributeSets: {},
        variables: {},
        ...$globalPageSettings.value.pdpConfiguration,
        ...$globalPageSettings.value.pdpConfigurationVariables
      };
    })
  );
}
