import { buildUrlString } from '~/utils/buildUrlString';

export function buildPdpUrl(
  locale: string,
  productUrlKey: string,
  withAbsolutePath: boolean | string = false,
  query?: string,
  anchor?: string
) {
  return buildUrlString(
    locale,
    `/shop/${productUrlKey}/`,
    query,
    anchor,
    withAbsolutePath
  );
}
