import { destr } from 'destr';
import type { CartType } from '~/stores/cartStore/model';
import {
  CART_TYPE_COOKIE_NAME_MAP,
  cookieSchema
} from '~/stores/cartStore/constants';
import { createCookieDomain } from '~/utils/shop/createCookieDomain';

export function useCartCookie(
  type: CartType,
  locale: string
): Ref<Record<string, string | null | undefined>> {
  const cookieName = CART_TYPE_COOKIE_NAME_MAP[type];
  const pdpConfiguration = usePdpConfiguration();
  const domain = createCookieDomain(locale, pdpConfiguration.value);

  // keep in mind that {@link ensureCartId} might also set a cookie!
  return useCookie<Record<string, string | undefined | null>>(cookieName, {
    default: () => ({}),
    // TODO: refactor to getter/setter as `decode` is performed on *every*
    //  cookie. @see useGeolocationCookie
    decode: (value) => cookieSchema.parse(destr(decodeURIComponent(value))),
    domain,
    sameSite: 'none',
    secure: true
  });
}
