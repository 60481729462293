export function findMap<T, U>(
  arr: T[],
  matcher: (item: T) => U | undefined | null
): U | undefined {
  for (const item of arr) {
    const result = matcher(item);

    if (result) {
      return result;
    }
  }
}
