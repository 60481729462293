import { acceptHMRUpdate, defineStore } from 'pinia';
import { isShowFlags } from './helper/isShowFlags';
import { useGeolocationCookie } from './useGeolocationCookie';
import { createCurrentCountryLabel } from './helper/createCurrentCountryLabel';
import { getClientLocale } from './helper/getClientLocale';
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import type { GeolocationResponseModel } from '~/server/api/geolocation/index.post';

export type GeoinformationStoreState = {
  clientLocale: string;
  /**
   * The preferred iso country code.
   *
   * @example "SG"
   */
  preferredCountry: string | null;
  /**
   * The preferred locale based on the preferred country.
   *
   * @example "en-SG"
   */
  preferredLocale: string | null;
  detectedCountry: string | null;
  detectedLocale: LOCALE_CODE | null;
  /**
   * `true` if the api was called for geoinformation.
   * If geoinformation has already been store inside the cookie,
   * this remains `false`.
   */
  geolocationLoaded: boolean;
  /**
   * `true` if  *client side information* hase been loaded.
   */
  initiated: boolean;
};

/**
 * Can not be serialized, therefore not stored in state.
 */
let geolocationPromise: Promise<GeolocationResponseModel> | null = null;

export const useGeoinformationStore = defineStore('geoinformation', {
  state: (): GeoinformationStoreState => ({
    clientLocale: getClientLocale(),
    // server side data must be null as response is cached inside cdn
    preferredCountry: null,
    preferredLocale: null,
    detectedCountry: null,
    detectedLocale: null,
    geolocationLoaded: false,
    initiated: false
  }),
  getters: {
    showCountryFlags: (state) => {
      const { $resolvedLocale } = useNuxtApp();

      return isShowFlags({
        detectedCountry: state.detectedCountry,
        contentfulLocale: $resolvedLocale.value
      });
    },
    currentRegionLabel: (state): string => {
      const { $resolvedLocale } = useNuxtApp();

      return createCurrentCountryLabel(
        state.preferredCountry ?? $resolvedLocale.value,
        $resolvedLocale.value,
        state.clientLocale
      );
    }
  },
  actions: {
    async ensureRedirectLocale(): Promise<LOCALE_CODE | undefined> {
      if (this.detectedLocale || this.geolocationLoaded) {
        return this.detectedLocale ?? undefined;
      }

      await this.loadGeolocation();

      return this.detectedLocale ?? undefined;
    },
    /**
     * Loads the geolocation if not already set.
     * The api endpoint is only called once.
     * Multiple calls to this function will not initate a new api request.
     */
    async loadGeolocation(): Promise<void> {
      if (this.detectedCountry) {
        return;
      }

      if (geolocationPromise) {
        await geolocationPromise;

        return;
      }

      try {
        geolocationPromise = $fetch<GeolocationResponseModel>(
          '/api/geolocation',
          {
            ...DEFAULT_FETCH_OPTIONS,
            method: 'post'
          }
        );

        const data = await geolocationPromise;

        if (!data.geolocation) {
          // failed to detect geolocation
          return;
        }

        this.detectedLocale = data.geolocation.detectedLocale ?? null;
        this.detectedCountry = data.geolocation.detectedCountry ?? null;

        const cookie = useGeolocationCookie();
        cookie.value = {
          ...cookie.value,
          detectedCountry: data.geolocation.detectedCountry,
          detectedLocale: data.geolocation.detectedLocale
        };
      } catch (error) {
        useLogger().error(
          'loadGeolocation',
          'failed to load geolocation: ',
          error
        );
      } finally {
        this.geolocationLoaded = true;
      }
    },
    updatePreferredSettings(preferredSettings: {
      locale?: string;
      country?: string;
    }): void {
      const cookie = useGeolocationCookie();

      this.preferredLocale = preferredSettings.locale ?? null;
      this.preferredCountry = preferredSettings.country ?? null;

      cookie.value = {
        ...cookie.value,
        preferredLocale: preferredSettings.locale,
        preferredCountry: preferredSettings.country
      };
    },
    async init() {
      this.clientLocale = getClientLocale();

      const cookie = useGeolocationCookie();
      this.preferredCountry = cookie.value?.preferredCountry ?? null;
      this.preferredLocale = cookie.value?.preferredLocale ?? null;
      this.detectedCountry = cookie.value?.detectedCountry ?? null;
      this.detectedLocale = cookie.value?.detectedLocale ?? null;

      await this.loadGeolocation();

      this.initiated = true;
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useGeoinformationStore, import.meta.hot)
  );
}
