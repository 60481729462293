export function createShippingTimeText({
  showShippingTime,
  shippingTime,
  fallbackLabel,
  labelTemplateSingle,
  labelTemplateMulti,
  labelTemplateMultiDays
}: {
  showShippingTime: boolean;
  shippingTime: number;
  fallbackLabel?: string;
  labelTemplateSingle?: string;
  labelTemplateMulti?: string;
  labelTemplateMultiDays?: string;
}): string | undefined {
  if (!showShippingTime) {
    return fallbackLabel?.replace(/(<([^>]+)>)/gi, '');
  }
  const weeks = Math.floor(shippingTime / 7);
  const template =
    shippingTime <= 3
      ? labelTemplateMultiDays
      : shippingTime > 3 && shippingTime <= 7
      ? labelTemplateSingle
      : labelTemplateMulti;
  const from = shippingTime <= 3 ? 2 : weeks;

  const to =
    shippingTime <= 3
      ? 3
      : shippingTime > 3 && shippingTime <= 90
      ? weeks + 1
      : weeks + 2;

  return template
    ?.replace(/(<([^>]+)>)/gi, '')
    ?.replace('{from}', from.toString())
    ?.replace('{to}', to.toString());
}
