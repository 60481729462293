export function failSafeParseJSON<T = unknown, TFallback = undefined>(
  jsonString: string | undefined,
  fallback?: TFallback
): T | TFallback {
  if (typeof jsonString === 'string') {
    try {
      return JSON.parse(jsonString);
    } catch (_e) {
      // XXX: console.error? Error callback?
    }
  }

  return fallback as TFallback;
}
