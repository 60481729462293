import type { Ref } from 'vue';
import type { ResolvedGlobalPageSettings } from '~/lib/ContentfulGraphqlService';
import { Tracer } from '~/lib/Tracing';

export function useGlobalPageSettings(
  locale: string | undefined
): Promise<Ref<ResolvedGlobalPageSettings | null>> {
  const tracer = new Tracer('composables');

  return tracer.withSpan(
    'useGlobalPageSettings',
    {
      'parameter.locale': locale
    },
    async () => {
      if (!locale) {
        return ref(null);
      }

      const { data, error } = await useFetch<ResolvedGlobalPageSettings>(
        '/api/contentful/globalPageSettings',
        {
          ...DEFAULT_FETCH_OPTIONS,
          query: { locale }
        }
      );

      if (error.value) {
        throw error.value;
      }

      if (!data.value) {
        throw createError(
          'failed to load GlobalPageSettings (useGlobalPageSettings)'
        );
      }

      return data;
    }
  );
}
