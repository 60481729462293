export default defineNuxtPlugin(() => {
  // XXX: we can not create our own vue-final-modal instance as hypercode
  //  components create their own instance.

  return {
    provide: {
      modals: {
        regionSelectorOpen: ref(false)
      }
    }
  };
});
