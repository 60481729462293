import type { Attributes, Span } from '@opentelemetry/api';
import opentelemetry, { SpanStatusCode } from '@opentelemetry/api';

export class Tracer {
  public constructor(private readonly name: string) {}

  public withSpan<T>(
    name: string,
    attributes: Attributes = {},
    fn: (span: Span) => Promise<T>
  ) {
    return opentelemetry.trace.getTracer(this.name).startActiveSpan(
      `${this.name}.${name}`,
      {
        attributes
      },
      async (span) => {
        try {
          return await fn(span);
        } catch (e) {
          if (e instanceof Error) {
            span.recordException(e);
            span.setStatus({
              code: SpanStatusCode.ERROR
            });
          } else {
            span.setStatus({
              code: SpanStatusCode.ERROR,
              message: typeof e === 'string' ? e : 'unknown'
            });
          }
          throw e;
        } finally {
          span.end();
        }
      }
    );
  }

  public withSyncSpan<T>(
    name: string,
    attributes: Attributes = {},
    fn: () => T
  ) {
    return opentelemetry.trace.getTracer(this.name).startActiveSpan(
      `${this.name}.${name}`,
      {
        attributes
      },
      (span) => {
        try {
          return fn();
        } catch (e) {
          if (e instanceof Error) {
            span.recordException(e);
            span.setStatus({
              code: SpanStatusCode.ERROR
            });
          } else {
            span.setStatus({
              code: SpanStatusCode.ERROR,
              message: typeof e === 'string' ? e : 'unknown'
            });
          }
          throw e;
        } finally {
          span.end();
        }
      }
    );
  }
}
