import type { GalleryItem } from '@hypercodestudio/basler-components/dist/components/modules/ProductDetailGallery.vue';
import { getIndexCustomAttributes } from './DecoratedProduct';
import { getCustomAttributeValue } from './getCustomAttributeValueFromMetadataCode';
import type { GenerateImageObjectOptions } from './generateImageObject';
import { generateImageObject } from './generateImageObject';
import type {
  CommonProductFragment,
  CustomAttributeFragment
} from '~/lib/Shop/generated/schema';
import {
  CAMERA_CATEGORY,
  CAMERA_SERIES_CODE,
  MODEL_NAME,
  PRODUCT_IMAGE
} from '~/utils/shop/constants';
import { isDefined } from '~/utils/guards/isDefined';
import type { ProductImageType } from '~/types/ProductImageType';

export function getProductImages(
  product: CommonProductFragment
): ProductImageType[] {
  const customAttributes = (product.custom_attributes ??
    []) as CustomAttributeFragment[];
  const productImages =
    getProductImagesFromCustomAttributes(customAttributes) ?? [];

  return [...productImages].sort(
    (a, b) => Number(a.productimagesorting) - Number(b.productimagesorting)
  );
}

export function getProductImage(
  product: CommonProductFragment,
  options: GenerateImageObjectOptions = {}
): GalleryItem[] {
  const customAttributes = (product.custom_attributes ??
    []) as CustomAttributeFragment[];

  const mappedCustomAttributes = getIndexCustomAttributes(customAttributes);

  const altTag = [
    getCustomAttributeValue(mappedCustomAttributes[CAMERA_SERIES_CODE]),
    getCustomAttributeValue(mappedCustomAttributes[MODEL_NAME]),
    getCustomAttributeValue(mappedCustomAttributes[CAMERA_CATEGORY])
  ].join(' ');

  const productImages = getProductImages(product);

  if (productImages.length > 0) {
    return productImages.map((img) => ({
      media: generateImageObject(
        {
          altTag,
          url: img.medialink,
          width: options.width,
          height: options.height
        },
        options
      )
    }));
  }

  return getDefaultImage(product.image?.url, altTag, options);
}

function getProductImagesFromCustomAttributes(
  customAttributes: ReadonlyArray<CustomAttributeFragment>
): ProductImageType[] | undefined {
  const productImage = customAttributes.find(
    (x) => x?.attribute_metadata?.code === PRODUCT_IMAGE
  )?.entered_attribute_value?.value;

  if (isDefined(productImage) && productImage !== '') {
    try {
      const parsedImage: ProductImageType[] = JSON.parse(productImage);
      const currentImgs = Object.values(parsedImage).filter(
        (img) => img.medialink !== ''
      );

      if (currentImgs.length > 0) {
        return currentImgs;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(
        'failed to parse product images from json:',
        productImage,
        error
      );
    }
  }
}

function getDefaultImage(
  url: string | null | undefined,
  altTag: string,
  options: GenerateImageObjectOptions
): GalleryItem[] {
  if (url) {
    return [
      {
        media: generateImageObject(
          {
            altTag,
            url,
            width: options.width,
            height: options.height
          },
          options
        )
      }
    ];
  }

  return [];
}
