import { z } from 'zod';
import type { CartType } from './model';

export const PRODUCT_CART_COOKIE_NAME = 'pdpCart';
export const REQUEST_CART_COOKIE_NAME = 'pdpRequestCart';

export const CART_TYPE_COOKIE_NAME_MAP: Record<CartType, string> = {
  cart: PRODUCT_CART_COOKIE_NAME,
  requestCart: REQUEST_CART_COOKIE_NAME
};

export const cookieSchema = z.record(
  z.union([z.string(), z.null(), z.undefined()])
);
