import { isDefined } from '~/utils/guards/isDefined';
import { buildUrlString } from '~/utils/buildUrlString';
import { useLocaleHostMap } from '~/composables/useHostname';

export function useSeoAlternateUrls() {
  const { $localeSlugMap } = useNuxtApp();
  const {
    public: { serverUrlOrigin }
  } = useRuntimeConfig();
  const localeHostMap = useLocaleHostMap();

  useHead({
    link: () =>
      Object.entries($localeSlugMap.value)
        ?.filter(([_, slug]) => isDefined(slug))
        .map(([locale, slug]) => {
          // can not reuse useHostname at this point
          const host = localeHostMap[locale.toLowerCase()] ?? serverUrlOrigin;

          return {
            rel: 'alternate',
            hreflang: convertContentfulToWebappLocaleCode(locale),
            href: buildUrlString(
              locale,
              slug as string,
              undefined,
              undefined,
              // we need to pass the url as we can not use composable
              // inside buildUrlString from this location
              host
            )
          };
        }) ?? []
  });
}
