import type { Entry, Asset } from 'contentful';
import { isEntryLink } from './isEntryLink';
import { isDefined } from '~/utils/guards/isDefined';

export function isEntryOrAsset(
  value: unknown
): value is Entry<unknown> | Asset {
  return (
    !isEntryLink(value) &&
    typeof value === 'object' &&
    isDefined(value) &&
    'sys' in value &&
    typeof value.sys === 'object' &&
    isDefined(value.sys) &&
    'type' in value.sys &&
    (value.sys.type === 'Asset' ||
      (value.sys.type === 'Entry' &&
        'contentType' in value.sys &&
        typeof value.sys.contentType === 'object' &&
        isDefined(value.sys.contentType) &&
        'sys' in value.sys.contentType &&
        typeof value.sys.contentType.sys === 'object' &&
        isDefined(value.sys.contentType.sys) &&
        'id' in value.sys.contentType.sys &&
        isDefined(value.sys.contentType.sys.id) &&
        'fields' in value &&
        typeof value.fields === 'object'))
  );
}
