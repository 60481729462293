import { formatMoney } from './formatMoney';
import type { MoneyFragment } from '~/lib/Shop/generated/schema';
import { clonePriceWithValue } from '~/utils/shop/clonePriceWithValue';

export function createPriceText({
  showPrice,
  locale,
  price,
  overwritePrice,
  onRequestLabel,
  prefix = ''
}: {
  showPrice: boolean;
  price: MoneyFragment | null | undefined;
  overwritePrice?: number;
  locale: string;
  onRequestLabel: string | undefined;
  prefix?: string;
}) {
  if (!showPrice) {
    return onRequestLabel?.replace(/(<([^>]+)>)/gi, '') ?? 'Price on request';
  }

  if (price?.value == null) {
    return '';
  }

  const actualPrice = overwritePrice
    ? clonePriceWithValue(price, overwritePrice)
    : price;

  return prefix + formatMoney(actualPrice, locale, true);
}
