import type { FetchOptions } from 'ofetch';

/**
 * Default fetch options which should be used for all requests.
 * This is important, as request have **no timeout** by default.
 *
 * @see https://github.com/unjs/ofetch#%EF%B8%8F-timeout
 */
export const DEFAULT_FETCH_OPTIONS: Pick<FetchOptions, 'retry' | 'timeout'> = {
  retry: 0,
  timeout: 2000
};
