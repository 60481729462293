const localeMapping: Record<
  Lowercase<string>,
  Lowercase<string> | undefined
> = {
  'zh-tw': 'zh-hant-tw',
  'zh-hant-tw': 'invalid'
};

export function convertWebappToContentfulLocaleCode(
  locale: string
): Lowercase<string> {
  const lowercasedLocale = locale.toLowerCase() as Lowercase<string>;

  return localeMapping[lowercasedLocale] ?? lowercasedLocale;
}
