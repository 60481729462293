type CurrentEnvName =
  | 'dev'
  | 'dev-preview'
  | 'stage'
  | 'stage-preview'
  | 'master'
  | 'master-preview'
  | 'unknown';

/**
 * Returns the current env-name based on the contentful env and preview setting.
 *
 * @param contentfulEnv The current contentful env.
 * @param previewEnabled FLag if preview is enabled.
 */
function getEnvFromFromContentfulEnv(
  contentfulEnv: 'master' | 'dev' | 'stage' | string,
  previewEnabled: boolean
): CurrentEnvName {
  if (contentfulEnv === 'dev') {
    return previewEnabled ? 'dev-preview' : 'dev';
  }
  if (contentfulEnv === 'stage') {
    return previewEnabled ? 'stage-preview' : 'stage';
  }
  if (contentfulEnv === 'master') {
    return previewEnabled ? 'master-preview' : 'master';
  }

  return 'unknown';
}

function useContentfulEnv() {
  const env = ref<CurrentEnvName>('unknown');
  if (import.meta.server) {
    // we only have access to these on sever side as they are not public.
    const { contentfulEnvironment, usePreviewApi } = useRuntimeConfig();
    env.value = getEnvFromFromContentfulEnv(
      contentfulEnvironment,
      usePreviewApi
    );
  }

  return env;
}

export default defineNuxtPlugin(() => {
  const currentEnvName = useState<CurrentEnvName>(
    'envName',
    () => useContentfulEnv().value
  );

  return {
    provide: {
      currentEnvName
    }
  };
});
