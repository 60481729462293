import { findMap } from './findMap';

export function matchOldPdpUrlKey(path: string): string | undefined {
  const matchedUrlKey = findMap(
    oldPDPUrls,
    (oldPDPUrl) => path.match(oldPDPUrl)?.[1]
  );

  return matchedUrlKey;
}

const oldPDPUrls = [
  /^\/en\/products\/cameras\/area-scan-cameras\/ace2\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/flaechenkameras\/ace2\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/matrichnye-kamery\/ace2\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/area-scan-cameras\/ace2\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/area-scan-cameras\/ace2\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/area-scan-cameras\/ace2\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/area-scan-cameras\/ace2\/(.+)\/.*/,
  /^\/en\/products\/cameras\/area-scan-cameras\/ace\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/flaechenkameras\/ace\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/matrichnye-kamery\/ace\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/area-scan-cameras\/ace\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/area-scan-cameras\/ace\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/area-scan-cameras\/ace\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/area-scan-cameras\/ace\/(.+)\/.*/,
  /^\/en\/products\/cameras\/area-scan-cameras\/boost\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/flaechenkameras\/boost\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/matrichnye-kamery\/boost\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/area-scan-cameras\/boost\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/area-scan-cameras\/boost\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/area-scan-cameras\/boost\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/area-scan-cameras\/boost\/(.+)\/.*/,
  /^\/en\/products\/cameras\/area-scan-cameras\/basler-beat\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/flaechenkameras\/basler-beat\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/matrichnye-kamery\/basler-beat\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/area-scan-cameras\/basler-beat\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/area-scan-cameras\/basler-beat\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/area-scan-cameras\/basler-beat\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/area-scan-cameras\/basler-beat\/(.+)\/.*/,
  /^\/en\/products\/cameras\/area-scan-cameras\/dart\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/flaechenkameras\/dart\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/matrichnye-kamery\/dart\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/area-scan-cameras\/dart\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/area-scan-cameras\/dart\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/area-scan-cameras\/dart\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/area-scan-cameras\/dart\/(.+)\/.*/,
  /^\/en\/products\/cameras\/area-scan-cameras\/pulse\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/flaechenkameras\/pulse\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/matrichnye-kamery\/pulse\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/area-scan-cameras\/pulse\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/area-scan-cameras\/pulse\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/area-scan-cameras\/pulse\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/area-scan-cameras\/pulse\/(.+)\/.*/,
  /^\/en\/products\/cameras\/line-scan-cameras\/racer\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/zeilenkameras\/racer\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/linejnye-kamery\/racer\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/line-scan-cameras\/racer\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/line-scan-cameras\/racer\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/line-scan-cameras\/racer\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/line-scan-cameras\/racer\/(.+)\/.*/,
  /^\/en\/products\/cameras\/3d-cameras\/basler-blaze\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/3d-kameras\/basler-blaze\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/3d-kamery\/basler-blaze\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/3d-cameras\/basler-blaze\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/3d-cameras\/basler-blaze\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/3d-cameras\/basler-blaze\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/3d-cameras\/basler-blaze\/(.+)\/.*/,
  /^\/en\/products\/cameras\/coaxpress-2-0-cameras\/cxp-12-evaluation-kit\/(.+)\/.*/,
  /^\/de\/produkte\/kameras\/coaxpress-2-0-kameras\/cxp-12-evaluation-kit\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery\/coaxpress-2-0-kamery\/cxp-12-evaluation-kit\/(.+)\/.*/,
  /^\/cn\/products\/cameras\/coaxpress-2-0-cameras\/cxp-12-evaluation-kit\/(.+)\/.*/,
  /^\/jp\/products\/cameras\/coaxpress-2-0-cameras\/cxp-12-evaluation-kit\/(.+)\/.*/,
  /^\/ko\/products\/cameras\/coaxpress-2-0-cameras\/cxp-12-evaluation-kit\/(.+)\/.*/,
  /^\/zh-tw\/products\/cameras\/coaxpress-2-0-cameras\/cxp-12-evaluation-kit\/(.+)\/.*/,
  /^\/en\/products\/lenses\/fixed-focal-lenses\/(.+)\/.*/,
  /^\/de\/produkte\/objektive\/objektive-mit-festbrennweite\/(.+)\/.*/,
  /^\/ru\/produkty\/obektivy\/obektivy-s-postoyannym-fokusnym-rasstoyaniem\/(.+)\/.*/,
  /^\/cn\/products\/lenses\/fixed-focal-lenses\/(.+)\/.*/,
  /^\/jp\/products\/lenses\/fixed-focal-lenses\/(.+)\/.*/,
  /^\/ko\/products\/lenses\/fixed-focal-lenses\/(.+)\/.*/,
  /^\/zh-tw\/products\/lenses\/fixed-focal-lenses\/(.+)\/.*/,
  /^\/en\/products\/lenses\/telecentric-lenses\/(.+)\/.*/,
  /^\/de\/produkte\/objektive\/telezentrische-objektive\/(.+)\/.*/,
  /^\/ru\/produkty\/obektivy\/telecentricheskie-obektivy\/(.+)\/.*/,
  /^\/cn\/products\/lenses\/telecentric-lenses\/(.+)\/.*/,
  /^\/jp\/products\/lenses\/telecentric-lenses\/(.+)\/.*/,
  /^\/ko\/products\/lenses\/telecentric-lenses\/(.+)\/.*/,
  /^\/zh-tw\/products\/lenses\/telecentric-lenses\/(.+)\/.*/,
  /^\/en\/products\/basler-lighting\/(.+)\/.*/,
  /^\/de\/produkte\/basler-beleuchtung\/(.+)\/.*/,
  /^\/ru\/produkty\/osveshchenie-basler-lighting\/(.+)\/.*/,
  /^\/cn\/products\/basler-lighting\/(.+)\/.*/,
  /^\/jp\/products\/basler-lighting\/(.+)\/.*/,
  /^\/ko\/products\/basler-lighting\/(.+)\/.*/,
  /^\/zh-tw\/products\/basler-lighting\/(.+)\/.*/,
  /^\/en\/products\/acquisition-cards\/usb-3-0-interface-cards\/(.+)\/.*/,
  /^\/de\/produkte\/bildeinzugskarten\/usb-3-0-interface-cards\/(.+)\/.*/,
  /^\/ru\/produkty\/platy-videozahvata\/interfejsnye-platy-usb-3-0\/(.+)\/.*/,
  /^\/cn\/products\/acquisition-cards\/usb-3-0-interface-cards\/(.+)\/.*/,
  /^\/jp\/products\/acquisition-cards\/usb-3-0-interface-cards\/(.+)\/.*/,
  /^\/ko\/products\/acquisition-cards\/usb-3-0-interface-cards\/(.+)\/.*/,
  /^\/zh-tw\/products\/acquisition-cards\/usb-3-0-interface-cards\/(.+)\/.*/,
  /^\/en\/products\/acquisition-cards\/gige-interface-cards\/(.+)\/.*/,
  /^\/de\/produkte\/bildeinzugskarten\/gige-interface-cards\/(.+)\/.*/,
  /^\/ru\/produkty\/platy-videozahvata\/interfejsnye-platy-gige\/(.+)\/.*/,
  /^\/cn\/products\/acquisition-cards\/gige-interface-cards\/(.+)\/.*/,
  /^\/jp\/products\/acquisition-cards\/gige-interface-cards\/(.+)\/.*/,
  /^\/ko\/products\/acquisition-cards\/gige-interface-cards\/(.+)\/.*/,
  /^\/zh-tw\/products\/acquisition-cards\/gige-interface-cards\/(.+)\/.*/,
  /^\/en\/products\/acquisition-cards\/cxp-12-interface-cards\/(.+)\/.*/,
  /^\/de\/produkte\/bildeinzugskarten\/cxp-12-interface-cards\/(.+)\/.*/,
  /^\/ru\/produkty\/platy-videozahvata\/interfejsnye-platy-cxp-12\/(.+)\/.*/,
  /^\/cn\/products\/acquisition-cards\/cxp-12-interface-cards\/(.+)\/.*/,
  /^\/jp\/products\/acquisition-cards\/cxp-12-interface-cards\/(.+)\/.*/,
  /^\/ko\/products\/acquisition-cards\/cxp-12-interface-cards\/(.+)\/.*/,
  /^\/zh-tw\/products\/acquisition-cards\/cxp-12-interface-cards\/(.+)\/.*/,
  /^\/en\/products\/acquisition-cards\/imaworx\/(.+)\/.*/,
  /^\/de\/produkte\/bildeinzugskarten\/imaworx\/(.+)\/.*/,
  /^\/ru\/produkty\/platy-videozahvata\/imaworx\/(.+)\/.*/,
  /^\/cn\/products\/acquisition-cards\/imaworx\/(.+)\/.*/,
  /^\/jp\/products\/acquisition-cards\/imaworx\/(.+)\/.*/,
  /^\/ko\/products\/acquisition-cards\/imaworx\/(.+)\/.*/,
  /^\/zh-tw\/products\/acquisition-cards\/imaworx\/(.+)\/.*/,
  /^\/en\/products\/acquisition-cards\/microenable-5-marathon\/(.+)\/.*/,
  /^\/de\/produkte\/bildeinzugskarten\/microenable-5-marathon\/(.+)\/.*/,
  /^\/ru\/produkty\/platy-videozahvata\/microenable-5-marathon\/(.+)\/.*/,
  /^\/cn\/products\/acquisition-cards\/microenable-5-marathon\/(.+)\/.*/,
  /^\/jp\/products\/acquisition-cards\/microenable-5-marathon\/(.+)\/.*/,
  /^\/ko\/products\/acquisition-cards\/microenable-5-marathon\/(.+)\/.*/,
  /^\/zh-tw\/products\/acquisition-cards\/microenable-5-marathon\/(.+)\/.*/,
  /^\/en\/products\/acquisition-cards\/microenable-5-ironman\/(.+)\/.*/,
  /^\/de\/produkte\/bildeinzugskarten\/microenable-5-ironman\/(.+)\/.*/,
  /^\/ru\/produkty\/platy-videozahvata\/microenable-5-ironman\/(.+)\/.*/,
  /^\/cn\/products\/acquisition-cards\/microenable-5-ironman\/(.+)\/.*/,
  /^\/jp\/products\/acquisition-cards\/microenable-5-ironman\/(.+)\/.*/,
  /^\/ko\/products\/acquisition-cards\/microenable-5-ironman\/(.+)\/.*/,
  /^\/zh-tw\/products\/acquisition-cards\/microenable-5-ironman\/(.+)\/.*/,
  /^\/en\/products\/cable\/(.+)\/.*/,
  /^\/de\/produkte\/kabel\/(.+)\/.*/,
  /^\/ru\/produkty\/kabeli\/(.+)\/.*/,
  /^\/cn\/products\/cable\/(.+)\/.*/,
  /^\/jp\/products\/cable\/(.+)\/.*/,
  /^\/ko\/products\/cable\/(.+)\/.*/,
  /^\/zh-tw\/products\/cable\/(.+)\/.*/,
  /^\/en\/products\/accessories-and-bundles\/(.+)\/.*/,
  /^\/de\/produkte\/zubehoer-und-bundles\/(.+)\/.*/,
  /^\/ru\/produkty\/aksessuary-i-komplekty\/(.+)\/.*/,
  /^\/cn\/products\/accessories-and-bundles\/(.+)\/.*/,
  /^\/jp\/products\/accessories-and-bundles\/(.+)\/.*/,
  /^\/ko\/products\/accessories-and-bundles\/(.+)\/.*/,
  /^\/zh-tw\/products\/accessories-and-bundles\/(.+)\/.*/,
  /^\/en\/products\/network-and-peripheral-devices\/(.+)\/.*/,
  /^\/de\/produkte\/netzwerk-und-peripheriegeraete\/(.+)\/.*/,
  /^\/ru\/produkty\/network-and-peripheral-devices\/(.+)\/.*/,
  /^\/cn\/products\/network-and-peripheral-devices\/(.+)\/.*/,
  /^\/jp\/products\/network-and-peripheral-devices\/(.+)\/.*/,
  /^\/ko\/products\/network-and-peripheral-devices\/(.+)\/.*/,
  /^\/zh-tw\/products\/network-and-peripheral-devices\/(.+)\/.*/,
  /^\/en\/products\/basler-pylon-vtools\/(.+)\/.*/,
  /^\/de\/produkte\/basler-pylon-vtools\/(.+)\/.*/,
  /^\/ru\/produkty\/basler-pylon-vtools\/(.+)\/.*/,
  /^\/cn\/products\/basler-pylon-vtools\/(.+)\/.*/,
  /^\/jp\/products\/basler-pylon-vtools\/(.+)\/.*/,
  /^\/ko\/products\/basler-pylon-vtools\/(.+)\/.*/,
  /^\/zh-tw\/products\/basler-pylon-vtools\/(.+)\/.*/,
  /^\/en\/products\/visualapplets\/(.+)\/.*/,
  /^\/de\/produkte\/visualapplets\/(.+)\/.*/,
  /^\/ru\/produkty\/visualapplets\/(.+)\/.*/,
  /^\/cn\/products\/visualapplets\/(.+)\/.*/,
  /^\/jp\/products\/visualapplets\/(.+)\/.*/,
  /^\/ko\/products\/visualapplets\/(.+)\/.*/,
  /^\/zh-tw\/products\/visualapplets\/(.+)\/.*/,
  /^\/en\/products\/medical-and-life-sciences-portfolio\/basler-med-ace-series\/(.+)\/.*/,
  /^\/de\/produkte\/medical-and-life-sciences-portfolio\/basler-med-ace-serie\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery-basler-dlya-mediciny-i-biologicheskih-nauk\/seriya-basler-med-ace\/(.+)\/.*/,
  /^\/cn\/products\/medical-and-life-sciences-portfolio\/basler-med-ace-series\/(.+)\/.*/,
  /^\/jp\/products\/medical-and-life-sciences-portfolio\/basler-med-ace-series\/(.+)\/.*/,
  /^\/ko\/products\/medical-and-life-sciences-portfolio\/basler-med-ace-series\/(.+)\/.*/,
  /^\/zh-tw\/products\/medical-and-life-sciences-portfolio\/basler-med-ace-series\/(.+)\/.*/,
  /^\/en\/products\/medical-and-life-sciences-portfolio\/basler-powerpack-for-microscopy\/(.+)\/.*/,
  /^\/de\/produkte\/medical-and-life-sciences-portfolio\/basler-powerpack-for-microscopy\/(.+)\/.*/,
  /^\/ru\/produkty\/kamery-basler-dlya-mediciny-i-biologicheskih-nauk\/basler-powerpack-for-microscopy\/(.+)\/.*/,
  /^\/cn\/products\/medical-and-life-sciences-portfolio\/basler-powerpack-for-microscopy\/(.+)\/.*/,
  /^\/jp\/products\/medical-and-life-sciences-portfolio\/basler-powerpack-for-microscopy\/(.+)\/.*/,
  /^\/ko\/products\/medical-and-life-sciences-portfolio\/basler-powerpack-for-microscopy\/(.+)\/.*/,
  /^\/zh-tw\/products\/medical-and-life-sciences-portfolio\/basler-powerpack-for-microscopy\/(.+)\/.*/,
  /^\/en\/solutions\/embedded-vision\/embedded-vision-portfolio\/embedded-vision-kits\/(.+)\/.*/,
  /^\/de\/loesungen\/embedded-vision\/embedded-vision-portfolio\/embedded-vision-kits\/(.+)\/.*/,
  /^\/ru\/resheniya\/vstraivaemye-sistemy-mashinnogo-zreniya\/portfolio-dlja-embedded-vision\/nabory-dlja-vstraivaemyh-sistem\/(.+)\/.*/,
  /^\/cn\/solutions\/embedded-vision\/embedded-vision-portfolio\/embedded-vision-kits\/(.+)\/.*/,
  /^\/jp\/solutions\/embedded-vision\/embedded-vision-portfolio\/embedded-vision-kits\/(.+)\/.*/,
  /^\/ko\/solutions\/embedded-vision\/embedded-vision-portfolio\/embedded-vision-kits\/(.+)\/.*/,
  /^\/zh-tw\/solutions\/embedded-vision\/embedded-vision-portfolio\/embedded-vision-kits\/(.+)\/.*/
];
