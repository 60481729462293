/**
 * Provides a debounce function for the given function.
 * @param func
 * @param wait
 * @param immediate
 */
export function debounce(
  func: Function,
  wait: number,
  immediate = false
): () => void {
  let timeout: number | null;

  return function (this: any, ...args) {
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      func.apply(this, args);
    }
  };
}
