import type { Ref } from 'vue';
import { convertWebappToContentfulLocaleCode } from '~/utils/convertWebappToContentfulLocaleCode';
import { firstEntry } from '~/utils/firstEntry';

/**
 * Returns the current active **mapped** locale represented in
 * the current route.
 * The returned locale is always in lowercase.
 * If no locale exists, an empty string is returned.
 */
export function useRouteParamLocale(): Ref<Lowercase<string>> {
  const route = useRoute();

  return computed(() => {
    // locale can never be `undefined` as this is a route parameter
    const localeParam = firstEntry(route.params.locale)?.toLowerCase() ?? '';

    return convertWebappToContentfulLocaleCode(localeParam);
  });
}

/**
 * Returns the current active locale mapped to a valid contentful locale with en as fallback;
 * The returned locale is always in lowercase.
 */
export function useLocale(): Ref<Lowercase<string>> {
  return useNuxtApp().$locale;
}
