import { isEntry } from './isEntry';
import type { GetContentType } from '~/types/GetContentType';
import type {
  IContentPage,
  IContentPageMultiLocale,
  IProductSeriesPage,
  ISolutionsArticlePage
} from '~/lib/ContentfulService';

export type IPage =
  | IContentPage
  | IContentPageMultiLocale
  | IProductSeriesPage
  | ISolutionsArticlePage;

export const contentPageContentIds = [
  'contentPage',
  'contentPageMultiLocale',
  'productSeriesPage',
  'solutionsArticlePage'
] satisfies GetContentType<IPage>[];

export function isContentPage(value: unknown): value is IPage {
  return (
    isEntry(value) &&
    contentPageContentIds.includes(
      value.sys?.contentType?.sys?.id as GetContentType<IPage>
    )
  );
}

export function isContentPageType(
  value?: string
): value is GetContentType<IPage> {
  return value != null && (contentPageContentIds as string[]).includes(value);
}
