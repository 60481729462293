import type { LOCALE_CODE } from '~/lib/ContentfulService';
import { isDefined } from '~/utils/guards/isDefined';

export function mapToFormattedDate(date: string, locale: LOCALE_CODE): string {
  const timeFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });

  return timeFormatter.format(new Date(date));
}

export function isDateTimeString(value: string | undefined | null): boolean {
  return (
    isDefined(value) &&
    isDefined(value.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/))
  );
}
export function isDateString(value: string | undefined | null): boolean {
  return (
    isDefined(value) && isDefined(value.match(/^(\d{4})-(\d{2})-(\d{2})$/))
  );
}
