import type { CartType, ModifyCartItemByDecoratedProduct } from '../model';
import { createEcommerceTrackingData } from './createEcommerceTrackingData';

export function createAddToCartAnalyticsEvent(
  type: CartType,
  cartId: string,
  cartItems: ReadonlyArray<ModifyCartItemByDecoratedProduct>,
  source?: string
) {
  if (!cartItems.length) {
    // eslint-disable-next-line no-console
    console.warn(
      'need at least one element to add to cart to create an analytics event - aborting.'
    );

    return;
  }

  const item = createEcommerceTrackingData({
    cartItems,
    source
  });

  switch (type) {
    case 'cart':
      return {
        event: 'add_to_cart',
        cart_id: cartId,
        ecommerce: item
      };
    case 'requestCart':
      return {
        event: 'request_quote',
        cart_id: cartId,
        custom: item
      };
  }
}
