import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { DictionaryModel } from '~/lib/ContentfulDictionaryService/model';

export default defineNuxtPlugin(() => {
  const dictionary = useState<DictionaryModel>('dictionary');
  const textDictionary = computed(() =>
    Object.fromEntries(
      Object.entries(dictionary.value).map(([key, value]) => [
        key,
        documentToPlainTextString(value)
      ])
    )
  );

  return {
    provide: {
      dictionary,
      textDictionary
    }
  };
});
