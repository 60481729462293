<template>
  <span v-if="isPreviewEnabled">
    Could not resolve {{ item?.details?.id }}
  </span>
</template>

<script setup lang="ts">
import type { ContentfulEntryNotResolvableError } from '~/utils/guards/isEntryResolveError';

interface Props {
  item: ContentfulEntryNotResolvableError;
}

defineProps<Props>();

const {
  public: { isPreviewEnabled }
} = useRuntimeConfig();
</script>
