export function getLocaleCountryLabel(
  formatCountryLocale?: string | undefined | null,
  targetLocale?: string | undefined | null,
  logger = console
): string {
  if (!formatCountryLocale || !targetLocale) {
    return '';
  }

  const [formatCountry] = formatCountryLocale.split('-').reverse();
  const [targetLanguage] = targetLocale.split('-');

  if (!formatCountry || !targetLanguage) {
    return '';
  }

  try {
    const regionName = new Intl.DisplayNames([targetLocale], {
      type: 'region'
    });

    return regionName.of(formatCountry) ?? '';
  } catch (e) {
    logger.warn(
      'could not get current language label.',
      'formatCountry',
      formatCountryLocale,
      'targetLocale',
      targetLocale,
      e
    );
  }

  return '';
}
