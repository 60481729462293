import { getLocaleCountryLabel } from './getLocaleCountryLabel';

export function createCurrentCountryLabel(
  preferredLocation: string | undefined,
  locale: string | undefined,
  clientLocale: string | undefined
) {
  const countryLabel = getLocaleCountryLabel(
    preferredLocation ?? locale,
    clientLocale
  );
  const languageCode = locale?.split('-')?.[0] ?? locale;

  if (countryLabel === languageCode) {
    return countryLabel;
  }

  return [countryLabel, languageCode].join(' - ');
}
