export function unique<T>(
  values: T[],
  selector: (value: T) => unknown = (value) => value
): T[] {
  return values.reduce(
    (newValues, value) =>
      newValues.some((newValue) => selector(newValue) === selector(value))
        ? newValues
        : newValues.concat(value),
    [] as T[]
  );
}
