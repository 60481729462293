import { isDefined } from '~/utils/guards/isDefined';

export type EntryLink = {
  sys: {
    id: string;
    linkType: string;
    type: 'Link';
  };
};

export function isEntryLink(value: unknown): value is EntryLink {
  return (
    typeof value === 'object' &&
    isDefined(value) &&
    'sys' in value &&
    typeof value.sys === 'object' &&
    isDefined(value.sys) &&
    'id' in value.sys &&
    'linkType' in value.sys &&
    (value.sys.linkType === 'Entry' || value.sys.linkType === 'Asset') &&
    'type' in value.sys &&
    value.sys.type === 'Link'
  );
}
