import type { Entry } from 'contentful';
import type { IMetadata } from '~/lib/ContentfulService';
import type { IPage } from '~/utils/guards/isContentPage';
import { isContentPage } from '~/utils/guards/isContentPage';
import { isDefined } from '~/utils/guards/isDefined';
import { buildUrlString } from '~/utils/buildUrlString';
import { usePageTitleState } from '~/composables/usePageTitleState';
import { useSeoAlternateUrls } from '~/composables/useSeoAlternateUrls';
import { useHostname } from '~/composables/useHostname';

export function calculateCanonical(
  canonical: Entry<unknown> | undefined,
  locale?: string
): string | undefined {
  if (!isContentPage(canonical)) {
    return;
  }
  const slug = canonical?.fields?.metadata?.fields?.slug;

  if (!slug) {
    return;
  }

  return buildUrlString(
    locale ?? canonical.sys.locale,
    slug,
    undefined,
    undefined,
    true
  );
}

export function calculateRobots(metaData?: IMetadata) {
  return [
    metaData?.fields?.seoRobotsIndexTag,
    metaData?.fields?.seoMetaRobotsFollowTag
  ]
    .filter(isDefined)
    .join(',');
}

export function calculateHead(
  metadata: IMetadata | undefined,
  companyName: string,
  canonical: string,
  fallbackLocale?: string
) {
  // Die Bilder funktionieren nicht auf den Sozialen Medien, weil das https: fehlt, dadurch wird die falsche HostAdresse benutzt.
  // Der fix gehört zu Ticket: WEB2-2310
  let ogImageUrl =
    metadata?.fields?.teaserImage?.fields?.image?.fields?.file?.url;
  const httpsPrefix = 'https:';
  const imageSettings = '?w=1200&fit=pad&bg=rgb:ffffff&q=80&fm=jpg';
  if (isDefined(ogImageUrl) && ogImageUrl?.startsWith('//')) {
    ogImageUrl = `${httpsPrefix}${ogImageUrl}${imageSettings}`;
  }

  return {
    title: metadata?.fields?.seoTitle
      ? metadata?.fields?.seoTitle + ' | ' + companyName
      : companyName,
    htmlAttrs: {
      lang: metadata?.sys.locale ?? fallbackLocale
    },
    meta: [
      {
        name: 'description',
        content: metadata?.fields?.seoDescription?.trim() ?? ''
      },
      {
        name: 'robots',
        content: calculateRobots(metadata)
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:url',
        content: canonical
      },
      {
        property: 'og:title',
        content: metadata?.fields?.teaserTitle?.trim() ?? ''
      },
      {
        property: 'og:description',
        content: metadata?.fields?.teaserText?.trim() ?? ''
      },
      {
        property: 'og:image',
        content: ogImageUrl ?? undefined
      }
    ]
  };
}

export function useMetadata(page: IPage | undefined, companyName: string) {
  const route = useRoute();
  const serverUrlOrigin = useHostname();
  const locale = useLocale();
  const pageTitle = usePageTitleState();

  const metadata = page?.fields?.metadata;
  const canonical =
    calculateCanonical(metadata?.fields?.seoCanonicalTag) ??
    serverUrlOrigin + route.path ??
    '';
  const head = calculateHead(metadata, companyName, canonical, locale.value);

  useHead(head);

  // necessary to be set in analytics
  pageTitle.value = head.title;

  if (metadata?.fields?.seoRobotsIndexTag !== 'NOINDEX') {
    useSeoAlternateUrls();

    useHead({
      link: [
        {
          rel: 'canonical',
          href: canonical
        }
      ]
    });
  }
}
