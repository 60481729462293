import { StatusCodes } from 'http-status-codes';
import type { RouteMiddleware } from 'nuxt/app';
import { defineNuxtRouteMiddleware } from '#imports';
import { firstEntry } from '~/utils/firstEntry';
import { enhancedNavigateTo } from '~/utils/enhancedNavigateTo';

export const enforceLowercasedLocaleHandlerMiddleware: RouteMiddleware = (
  to
) => {
  const localeString = firstEntry(to.params.locale);

  if (!localeString) {
    return;
  }

  const lowercasedLocaleString = localeString.toLowerCase();

  if (localeString !== lowercasedLocaleString) {
    return enhancedNavigateTo(
      {
        query: to.query,
        path: to.path.replace(localeString, lowercasedLocaleString)
      },
      {
        replace: true,
        redirectCode: StatusCodes.MOVED_PERMANENTLY
      }
    );
  }
};

export default defineNuxtRouteMiddleware(
  enforceLowercasedLocaleHandlerMiddleware
);
