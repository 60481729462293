import type { ErrorTypes, NavigationFailure } from 'vue-router';

export function isNavigationFailure(
  failure: void | NavigationFailure | undefined,
  navigationFailureType:
    | ErrorTypes.NAVIGATION_ABORTED
    | ErrorTypes.NAVIGATION_CANCELLED
    | ErrorTypes.NAVIGATION_DUPLICATED
): boolean {
  if (!(failure instanceof Error)) {
    return false;
  }

  return !!(failure.type & navigationFailureType);
}
