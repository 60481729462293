import { StatusCodes } from 'http-status-codes';
import type { RouteMiddleware } from 'nuxt/app';
import { Tracer } from '~/lib/Tracing';
import { matchOldPdpUrlKey } from '~/utils/matchOldPdpUrlKey';
import { enhancedNavigateTo } from '~/utils/enhancedNavigateTo';
import type { OldLocales } from '~/utils/oldToCurrentLocaleMap';

const oldPDPRedirect: RouteMiddleware = async () => {
  const tracer = new Tracer('middleware');

  await tracer.withSpan('pageRedirect', {}, async () => {
    // Global server middleware handles serverside redirects.
    if (process.server) {
      return;
    }
    // Ignore initial loading of a page.
    const nuxtApp = useNuxtApp();
    if (
      process.client &&
      nuxtApp.isHydrating &&
      nuxtApp.payload.serverRendered
    ) {
      return;
    }
    const url = useRequestURL();

    const matchedOldPdpUrlKey = matchOldPdpUrlKey(url.pathname);

    if (!matchedOldPdpUrlKey) {
      return;
    }

    const oldLocale = url.pathname.split('/').filter(Boolean).at(0) ?? '';
    const locale = oldToCurrentLocaleMap[oldLocale as OldLocales];

    if (!locale) {
      return;
    }

    return enhancedNavigateTo(
      {
        query: Object.fromEntries(url.searchParams.entries()),
        force: true,
        path: buildPdpUrl(locale, matchedOldPdpUrlKey)
      },
      {
        redirectCode: StatusCodes.MOVED_PERMANENTLY,
        external: false
      }
    );
  });
};

export default defineNuxtRouteMiddleware(oldPDPRedirect);
