import type { CustomAttributeFragment } from '~/lib/Shop/generated/schema';
import { isDefined } from '~/utils/guards/isDefined';

export function getCustomAttributeValue(
  customAttribute: CustomAttributeFragment | undefined,
  options: { label: boolean } = { label: true }
): string | undefined {
  // some boolean attributes contain an value property
  return (
    customAttribute?.selected_attribute_options?.attribute_option
      ?.map((entry) => (options?.label ? entry?.label : entry?.value))
      .filter(isDefined)
      .join(', ') ??
    customAttribute?.entered_attribute_value?.value ??
    undefined
  );
}

export function getCustomAttributeFirstValue(
  customAttribute: CustomAttributeFragment | undefined,
  options: { label: boolean } = { label: true }
): string | undefined {
  // some boolean attributes contain an value property
  return (
    customAttribute?.selected_attribute_options?.attribute_option
      ?.map((entry) => (options?.label ? entry?.label : entry?.value))
      .filter(isDefined)
      .find(isDefined) ??
    customAttribute?.entered_attribute_value?.value ??
    undefined
  );
}

export function getCustomAttributeArrayValue(
  customAttribute: CustomAttributeFragment | undefined,
  options: { label: boolean } = { label: true }
): string[] | undefined {
  // some boolean attributes contain an value property
  const filteredValues =
    customAttribute?.selected_attribute_options?.attribute_option
      ?.map((entry) => (options?.label ? entry?.label : entry?.value))
      .filter(isDefined);

  if (filteredValues?.length) {
    return filteredValues;
  }

  const enteredValue = customAttribute?.entered_attribute_value?.value;

  if (isDefined(enteredValue)) {
    return [enteredValue];
  }

  return undefined;
}
