export type ObjectEntries<T extends object> = Exclude<
  {
    [K in keyof T]: [K, T[K]];
  }[keyof T],
  undefined
>[];

export function objectEntries<T extends object>(value: T) {
  return Object.entries(value) as ObjectEntries<T>;
}
