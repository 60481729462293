import { defineNuxtPlugin } from 'nuxt/app';
import { useCartStore } from '~/stores/cartStore/cartStore';

declare global {
  interface Window {
    baslerApi: {
      // XXX: Types of property 'decoratedProduct' are incompatible.
      // Types have separate declarations of a private property 'shippingTime'.
      // although both are { quantity: number; } ???
      cart: any;
    };
  }
}

/**
 * This plugin is used by basler tools e.g. to modify the cart.
 */
export default defineNuxtPlugin(() => {
  const cartStore = useCartStore();

  window.baslerApi = {
    cart: {
      addProducts: cartStore.addProducts
    }
  };
});
