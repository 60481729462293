import type { PdpConfiguration } from '~/composables/usePdpConfiguration';

export function createCookieDomain(
  locale: string,
  pdpConfiguration?: PdpConfiguration
): string | undefined {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  return (
    pdpConfiguration?.localeCartCookieDomainMap?.[locale] ??
    pdpConfiguration?.localeCartCookieDomainMap?.default
  );
}
