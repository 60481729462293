import { convertWebappToContentfulLocaleCode } from '~/utils/convertWebappToContentfulLocaleCode';

export function useLocaleHostMap() {
  const {
    public: { localeHostMapString }
  } = useRuntimeConfig();
  let localeHostMap: Partial<Record<string, string>> = {};
  if (typeof localeHostMapString === 'string') {
    try {
      localeHostMap = JSON.parse(localeHostMapString);
    } catch (e) {
      // XXX: can't use useLogger as it relies on useNuxtApp which is
      //  not available everywhere
      // eslint-disable-next-line no-console
      console.warn('could not parse "localeHostMapString"', e);
    }
  } else if (typeof localeHostMapString === 'object') {
    localeHostMap = localeHostMapString;
  }

  return localeHostMap;
}

/**
 * Composable to retrieve the hostname based on the current locale.
 * The locale is inferred from the request url.
 * The given locale should matcht the *webapp* locale (e.g. 'zh-tw' for taiwan).
 */
export function useHostname(locale?: string, requestUrl?: URL): string {
  if (!locale) {
    // We might not have access to vue-related composable
    // after async context is no longer experimental, we could use `useEvent
    // const requestUrl = getRequestURL(useEvent());
    // useRequestURL fails outside of vue components!
    requestUrl ??= useRequestURL();
    const [language, country] =
      requestUrl.pathname.split('/')?.[0]?.split('-') ?? [];
    if (language && country) {
      locale = convertWebappToContentfulLocaleCode(
        `${language}-${country.toUpperCase()}`
      );
    }
  } else {
    locale = locale.toLowerCase();
  }

  return useLocaleHostMap()[locale ?? ''] ?? useDefaultHostname();
}

export function useDefaultHostname(): string {
  return useRuntimeConfig().public.serverUrlOrigin;
}
