import type { LOCALE_CODE } from '~/lib/ContentfulService';

// XXX: move to public env variable or global page settings
const DETECTED_COUNTRIES_WITHOUT_FLAGS = ['zh', 'hk', 'jp', 'kr', 'sg'];
const LOCALES_WITHOUT_FLAGS: LOCALE_CODE[] = [
  'ja-JP',
  'ko-KR',
  'zh-CN',
  'zh-Hant-TW'
];

export type IsShowFlagsOptions = {
  contentfulLocale?: LOCALE_CODE;
  detectedCountry?: string | null;
};

/**
 * Checks if flags should be visible with the given locale information.
 *
 * @param contentfulLocale The contentful locale ot use. Uses 'en' as fallback.
 * @param detectedCountry The detected country to use.
 */
export function isShowFlags({
  contentfulLocale = 'en',
  detectedCountry
}: IsShowFlagsOptions): boolean {
  if (
    detectedCountry &&
    DETECTED_COUNTRIES_WITHOUT_FLAGS.includes(detectedCountry.toLowerCase())
  ) {
    return false;
  }

  return !LOCALES_WITHOUT_FLAGS.includes(contentfulLocale);
}
